import React from 'react'
import Img from "gatsby-image";
import styled from 'styled-components'

import { FaAngleDoubleDown } from 'react-icons/fa';

const Hero = props => (
  <header className={props.class + " header intro"} style={{position: "relative"}}>
    <Img
       title="Header image"
       alt="Hero banner"
       sizes={props.headerImage.sizes}
       style={{
          position: "absolute",
          left: 0,
          top: 0,
          width: "100%",
          height: "100%"
        }}
     />
   <div className=" container intro-body">
       <div className="row justify-content-center">
         <div className="col-md-12">
           <h1 className="brand-heading">{props.heading}</h1>
           <p className="intro-text">{props.text}</p>
           <DownArrow href="#about" aria-label="about Jennifer">
               <FaAngleDoubleDown />
            </DownArrow>
         </div>
       </div>
     </div>
  </header>
);

const DownArrow = styled.div`
  width: 70px;
  height: 70px;
  margin: 20px auto;
  margin-top: 15px;
  padding: 7px 16px;
  border: 2px solid #fff;
  border-radius: 35px;
  font-size: 40px;
  color: #fff;
  background: 0 0;
  transition: all .3s ease-in-out;
  cursor: pointer;
`

export default Hero
