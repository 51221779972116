import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Img from "gatsby-image";

import styled from 'styled-components'

import Layout from '../components/Layout'
import Hero from '../components/Hero'
import favicon from '../../static/favicon.ico';

class Index extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const siteDescription = get(
      this,
      'props.data.site.siteMetadata.description'
    )
    const siteKeywords = get(
      this,
      'props.data.site.siteMetadata.siteKeywords'
    )
    const siteUrl = get(
      this,
      'props.data.site.siteMetadata.siteUrl'
    )
    const image = siteUrl + get(this, 'props.data.newheadshotImage.sizes.src');

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Helmet
          htmlAttributes={{ lang: 'en' }}
          meta={[
            //html tags
            { name: 'description', content: siteDescription },
            { name: 'keywords', content: siteKeywords},
            { itemprop: 'name', content: siteTitle },
            { itemprop: 'description', content: siteDescription},
            { itemprop: 'image', content: image},
            //facebook tags
            {property: 'og:title', content: siteTitle},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: image},
            {property: 'og:url', content: siteUrl},
            {property: 'og:description', content: siteDescription},
            //twitter tags
            {property: 'twitter:card', content: 'twitter:card'},
            {property: 'twitter:title', content: siteTitle},
            {property: 'twitter:creator', content: '@likeOMGitsFEDAY'},
            {property: 'twitter:image', content: image},
            {property: 'twitter:url', content: siteUrl},
            {property: 'twitter:description', content: siteDescription}
          ]}
          title={siteTitle}
          link={[{"rel": "icon", "type": "image/png", "href": "favicon.ico"}
             ]}
        />
        <Hero headerImage={this.props.data.headerImage}
          heading="Jennifer Wadella"
          text="Often described as a force of nature,
            Jennifer is a JavaScript developer, speaker, foodie, fitness geek,
            and community organizer most well known for her work creating
            innovative and highly sought-after programs for women in technology" />
        <div className="container content-section text-center aboutContainer" id="about">
          <div className="row">
            <div className="col-4">
              <Img
                 title="Jennifer Wadella headshot"
                 alt="Jennifer Wadella headshot"
                 sizes={this.props.data.headshotImage.sizes}
                 style={{borderRadius: '50%'}}
               />
            </div>
            <div className="justify-content-center col-8">
              <h2>About Jennifer</h2>
                <p>Jennifer is a software engineer who has been writing code since before she
                  realized it was a credible career path. She currently works as the Director of Angular Development at  
                  {' '}<a href="https://bitovi.com" target="_blank" rel="noopener">Bitovi</a> and is a
                  {' '}<a href="https://developers.google.com/experts/" target="_blank" rel="noopener"> Google Developer Expert</a>.</p>
                <p>She is widely known for her work in the Kansas City community, and in 2013 founded
                {' '}<a href="http://kcwomenintech.org" target="_blank" rel="noopener">Kansas City Women in Technology</a>,
                   a 501(c)(3) organization to help grow the number of women in technology careers in
                   Kansas City. She built numerous coding programs from the ground up to support KCWiTs
                   mission, including {' '}<a href="http://coderdojok.com" target="_blank" rel="noopener"> CoderDojoKC</a>,
                   {' '}<a href="http://codingandcupcakes.kcwomenintech.org" target="_blank" rel="noopener"> Coding &amp; Cupcakes</a>,
                   {' '}<a href="http://codingandcocktails.kcwomenintech.org" target="_blank" rel="noopener"> Coding &amp; Cocktails</a>,
                  and the Kansas City Django Girls workshop. She has worked tirelessly to build a robust and
                  thriving network of women in technology through creating learning, volunteering, mentoring,
                  speaking, and leadership opportunities for the those in her community.</p>
                <p>Jennifer began her speaker career shortly after launching KCWiT, hoping not only to share
                  tips on coding, leadership, and community building but to inspire other women to start speaking
                  as well. Jennifer is now a sought-after international speaker who brings passion and excitement
                  to her talks coupled with tangible, immediately executable strategies and a <b>no-excuses-get-shit-done </b>
                  attitude.</p>
                  <p>When shes not coding, speaking, or running operations at KCWiT, Jennifer is an avid cook,
                    foodie, bourbon drinker, furbaby momma, and fitness geek never going more than 3 days without lifting heavy objects.</p>
            </div>
          </div>
        </div>
        <Mentoring className="content-section text-center" style={{position: 'relative', padding: '100px 0'}}>
          <Img
             title="Jennifer mentoring"
             alt="Jennifer mentoring"
             sizes={this.props.data.mentorImage.sizes}
             style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%"
              }}
           />
          <div className="container">
            <div className="col-lg-6 col-lg-offset-4">
                <h2>Schedule a meeting</h2>
                <p>Looking for advice on getting started speaking, building a community, or navigating the non-profit world?</p>
                <a href="http://calendly.com/jennifer-wadella" target="_blank" className="btn btn-default btn-lg" rel="noopener">Book a Meeting</a>
            </div>
          </div>
        </Mentoring>
        <div className="container content-section text-center contactContainer" id="contact">
          <div className="row justify-content-center">
            <div className="col-md-12">
              <h2>Contact</h2>
              <p>Get in touch. Guaranteed reponse in one month or less.</p>
              <p><a href="mailto:jennifer.wadella[at]gmail[dot]com">jennifer.wadella[at]gmail[dot]com</a></p>
              <ul className="list-inline banner-social-buttons">
                <li>
                    <a target="_blank" href="https://twitter.com/likeOMGitsFEDAY" className="btn btn-default btn-lg" rel="noopener"><i className="fa fa-twitter fa-fw"></i> <span className="network-name">twitter</span></a>
                </li>
                <li>
                    <a target="_blank" href="https://github.com/tehfedaykin" className="btn btn-default btn-lg" rel="noopener"><i className="fa fa-github fa-fw"></i> <span className="network-name">github</span></a>
                </li>
                <li>
                    <a href="/resume" className="btn btn-default btn-lg"><i className="fa fa-newspaper-o fa-fw"></i> <span className="network-name">resume</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Index


const Mentoring = styled.div`
  position: relative;
  padding: 100px 0;
  color: #fff;
  h2, p {
    font-family: Montserrat,"Helvetica Neue",Helvetica,Arial,sans-serif;
    text-shadow: 1px 2px 3px #000;
  }
  h2 {
    font-weight: 700;
  }
  a {
    color: #219ab3 !important;
    &:hover {
      color: #fff !important;
    }
  }
  a:hover {
    color: #fff;
  }
`

export const pageQuery = graphql`
  query HeaderImageQuery {
    headerImage: imageSharp(original: {src: { regex: "/cocktails1/" }} ) {
      sizes(maxWidth: 1240 ) {
        ...GatsbyImageSharpSizes
      }
    }
    headshotImage: imageSharp(original: {src: { regex: "/2019headshot/" }} ) {
      sizes(maxWidth: 1240 ) {
        ...GatsbyImageSharpSizes
      }
    }
    mentorImage: imageSharp(original: {src: { regex: "/mentor-home/" }} ) {
      sizes(maxWidth: 1240 ) {
        ...GatsbyImageSharpSizes
      }
    }
    newheadshotImage: imageSharp(original: {src: { regex: "/jennifer-wadella-headshot18/" }} ) {
      sizes(maxWidth: 1240 ) {
        ...GatsbyImageSharpSizes
      }
    }
    site {
      siteMetadata {
        title
        description
        siteKeywords
        siteUrl
      }
    }
  }
`
